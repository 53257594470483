import React from "react";
import * as Yup from "yup";
import Button from "reactstrap/lib/Button";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faRuble from "@fortawesome/fontawesome-free-solid/faRubleSign";
import isEmpty from "lodash/isEmpty";
import { Formik, Form, Field, yupToFormErrors } from "formik";

import SpinnerButton from "../../../../../Components/Common/Buttons/SpinnerButton";
import {
  TsSelect as FormikAutoSelect,
  FormikSelect,
  TimePicker
} from "../../../../../Components/Common/Inputs";
import Alert from "reactstrap/lib/Alert";
import IntervalsBlock from "../../../../../Components/ProfileComponents/Payment/IntervalsBlock";
import AutoSubmit from "./../../../../../Components/Common/Inputs/AutoSubmit";
import Cost from "./Cost";
import CarNumberStore from "../../../../../Stores/CarNumberStore";
import { defaultTs } from "../../../../../Stores/UserStore";
import UserStore from "../../../../../Stores/UserStore";

const schema = t =>
  Yup.object().shape({
    parking: Yup.object()
      .nullable()
      .required(t("errors:RequiredField")),
    vehicle: Yup.object()
      .nullable()
      .required(t("errors:RequiredField"))
      .test({
        message: t("profile:transports:edit:errors:format"),
        test: (value) => {
          if (value && value.formatGrz && value.formatGrz === UserStore.GRZ_FORMATS.FOREIGN) return true;
          return value && value.numberTs && CarNumberStore.validateNumberByType(value.numberTs).isValid;
        }
    }),
    duration: Yup.number()
      .nullable(t("errors:RequiredField"))
      .when("type", {
        is: "post",
        then: Yup.number().required(t("errors:RequiredField"))
      }),
    postStart: Yup.date()
      .nullable()
      .when("type", {
        is: "post",
        then: Yup.date()
          .max(new Date(), t("errors:FuturePost"))
          .required(t("errors:RequiredField"))
      }),
    type: Yup.string()
  });

const handleInputChange = (e, handleSelectChange) => handleSelectChange(null, {...defaultTs, numberTs: e.target.value});

const NoBalance = ({
  t,
  ts,
  parkings,
  durations,
  onSubmit,
  costData = null,
  onChange,
  inProcess,
  initialValues
}) => {
  const handleParkingSubmit = async (values, { resetForm, setStatus }) => {
    if (onSubmit) {
      const response = await onSubmit(values);

      if (response && response.status !== "ok") {
        setStatus(t(`errors:${response.errorName}`));
      } else {
        setStatus(null);
      }
      resetForm({});
    }
  };

  const submitText = type => {
    if (Boolean(costData) && costData.totalcost === 0) {
      return t("reservation:parking:free");
    }

    if (type !== "today") {
      return t("operations:payForParking");
    }

    return t("operations:startParking");
  };

  return (
    <div className="main-wrapper">
      <Formik
        initialValues={{
          vehicle: {numberTs: ''},
          parking: initialValues.parking,
          type: "today",
          duration: "",
          postStart: null
        }}
        validationSchema={schema(t)}
        validateOnBlur={false}
        onSubmit={handleParkingSubmit}
      >
        {({
          isSubmitting,
          status,
          setFieldValue,
          setFieldTouched,
          values,
          touched,
          errors,
          isValid
        }) => (
          <Form>
            {status ? <Alert color="danger">{status}</Alert> : null}
            <Field
              label={t("parking:payments:labels:zone")}
              name="parking"
              options={parkings}
              getOptionLabel={parking => parking.address}
              component={FormikAutoSelect}
            />
            <Field
              label={t("parking:payments:labels:vehicleNumber")}
              name="vehicle"
              options={ts}
              noOptionsText={t("parking:payments:labels:noTs")}
              getOptionLabel={singleTs => singleTs.numberTs}
              handleInputChange={handleInputChange}
              freeSolo
              component={FormikAutoSelect}
            />
            {values.type === "post" && (
              <Field
                name="duration"
                label={t("parking:payments:labels:duration")}
                items={durations}
                component={FormikSelect}
              />
            )}

            <div style={{ marginTop: 15, marginBottom: 5 }}>
              <Field
                name="type"
                color="primary"
                className="payment"
                outline={values.type !== "today"}
                onClick={() => {
                  setFieldTouched("postStart", false);
                  setFieldValue("postStart", null);
                  setFieldValue("type", "today");
                }}
                component={Button}
                style={{ marginRight: 5 }}
              >
                {t("parking:payments:paymentTypes:today")}
              </Field>
              <Field
                name="type"
                color="primary"
                className="payment"
                outline={values.type !== "post"}
                onClick={() => {
                  setFieldTouched("duration", false);
                  setFieldTouched("postStart", false);
                  setFieldValue("duration", null);
                  setFieldValue("type", "post");
                }}
                component={Button}
              >
                {t("parking:payments:paymentTypes:postPayment")}
              </Field>
            </div>

            {values.type === "post" ? (
              <div style={{ marginBottom: 15, marginTop: 15 }}>
                <span style={{fontSize:"80%", fontWeight:"bold", paddingRight:'1em'}}>{t("parking:payments:timePicker:label")} </span>
                <TimePicker
                  placeholder={t("parking:payments:timePicker:placeholder")}
                  error={!!errors.postStart && touched.postStart}
                  errorText={errors.postStart}
                  onChange={times => {
                    setFieldTouched("postStart", true);
                    setFieldValue("postStart", times.toDate());
                  }}
                />
                <div>
                  <p
                    style={{ fontSize: 13, marginTop: "1rem", marginBottom: 0 }}
                  >
                    {t("parking:payments:postPaymentMessages:first")}
                    <br />
                    {t("parking:payments:postPaymentMessages:second")}
                    <br />
                    <span style={{ fontWeight: 600 }}>
                      {t("parking:payments:postPaymentMessages:third")}
                    </span>
                  </p>
                </div>
              </div>
            ) : null}

            {isValid && values.type === "today" && costData ? (
              <div>
                {t("parking:payments:costPerHour")}: {costData.price}{" "}
                <FontAwesomeIcon
                  style={{ fontSize: 10, fontWeight: 100 }}
                  icon={faRuble}
                />
              </div>
            ) : null}
            {isValid && values.type === "post" ? (
              <div style={{ margin: "15px 0" }}>
                <Cost costData={costData} />
                <IntervalsBlock
                  costData={costData ? costData.costData : null}
                />
              </div>
            ) : null}

            <SpinnerButton
              color="primary"
              type="submit"
              className="submit"
              style={{ marginTop: 10 }}
              isLoading={isSubmitting}
              disabled={
                values.type === "post" && costData === null && inProcess
              }
            >
              {submitText(values.type)}
            </SpinnerButton>
            <AutoSubmit
              submitForm={() => {
                onChange(values);
              }}
              values={values}
              isValid={isValid && isEmpty(errors)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NoBalance;
