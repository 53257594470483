const styles = {
  default: {
    color: window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--bgMain'),
    weight: 5,
    opacity: 0.65,
  },
  light: {
    color: '#008000',
    weight: 5,
    opacity: 0.65,
  },
  medium: {
    color: 'rgb(255, 204, 0)',
    weight: 5,
    opacity: 0.65,
  },
  high: {
    color: 'rgb(239, 56, 89)',
    weight: 5,
    opacity: 0.65,
  },
  parking: {
    color: '#0F9CDC',
    weight: 5,
    opacity: 0.65,
  },
  inactive: {
    color: 'black',
    weight: 5,
    opacity: 0.65,
  },
};

export default styles;
