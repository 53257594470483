import i18n from '../i18n/i18n';
import SettingsStore from './SettingsStore';
import { FEEDBACK } from './../Config';

class ProfileStore {
  get menuItems() {
    const menus = [
      {
        type: 'parking',
        name: SettingsStore.settings.dontUseBalance
          ? i18n.t('profile:menu:parkingSessions')
          : i18n.t('profile:menu:parking')
      },
      {
        type: 'operations',
        name: i18n.t('profile:menu:operations')
      },
      {
        type: 'abonements',
        name: i18n.t('profile:menu:abonements')
      },
      {
        type: 'my-profile',
        name: i18n.t('profile:menu:profile')
      },
    ];

    if (SettingsStore.settings.useReceipts) {
      menus.push({
        type: 'receipts',
        name: 'Квитанции'
      });
    }

    if (SettingsStore.settings.viewPenalty) {
      menus.push({
        type: 'penalty',
        name: i18n.t('profile:menu:penalty')
      });
    }

    if (FEEDBACK) {
      menus.push({
        type: 'feedback',
        name: i18n.t('feedback:title')
      });
    }

    return menus;
  }
}

export default new ProfileStore();
