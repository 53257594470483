import { observable, action, runInAction, computed } from 'mobx';
import APIProvider from './APIProvider';

import i18n from '../i18n/i18n';
import moment from 'moment';

import config from '../Config';

import OBJ_TYPES from '../constants/objectTypes';
import PARK_TYPES from '../constants/parkTypes';

class MapStore {
  @observable data = {};
  @observable status = 'waiting';
  @observable filteredData = {};
  @observable zones = {
    done: false,
    isLoading: false,
    error: false,
  };

  @observable
  currentCountOfObjects = {
    parkings: 0,
    places: 0,
    freePlaces: 0,
  };

  defaultObjectIndexes = {
    [PARK_TYPES.ROADSIDE]: [],
    [PARK_TYPES.MULTILEVEL]: [],
    [PARK_TYPES.PLANAR]: [],
    [PARK_TYPES.COMMERCE]: [],
    [PARK_TYPES.FREE]: [],
    [PARK_TYPES.FORDISABLED]: [],
    [PARK_TYPES.POCKET]: [],
    [PARK_TYPES.PARKOMAT]: [],
    [PARK_TYPES.PAYMENTTERMINAL]: [],
    [PARK_TYPES.INFORMATIONTABLE]: [],
    [PARK_TYPES.MUSEUM]: [],
    [PARK_TYPES.SIGHT]: [],
    [PARK_TYPES.CHURCH]: [],
  };

  objectIndexes = {
    ...this.defaultObjectIndexes,
  };

  MAP_LOAD_AT = 'mapLoadAt';

  setLastTimeMaploading() {
    const currentDate = new Date();
    window.localStorage.setItem(this.MAP_LOAD_AT, currentDate);
  }

  getLastTimeMaploading() {
    const lastTime = window.localStorage.getItem(this.MAP_LOAD_AT);
    return lastTime ? Date.parse(lastTime) : null;
  }

  isNeededToLoad() {
    return (
      this.status !== 'pending' || this.isMapEmpty() || !this.isLastMapFresh()
    );
  }

  isMapEmpty() {
    return !(this.data.features && this.data.features.length);
  }

  isLastMapFresh() {
    const lastTimeMapDownloaded = this.getLastTimeMaploading();
    if (lastTimeMapDownloaded) {
      const lastTimeMapLoadedMoment = moment(lastTimeMapDownloaded);
      const currentDate = moment();

      const duration = moment.duration(
        currentDate.diff(lastTimeMapLoadedMoment)
      );
      const hours = duration.asHours();
      return hours < config.mapLifeTime || 1;
    }

    return false;
  }

  get currentCountOfObjects() {
    return this.currentCountOfObjects;
  }

  get categories() {
    return [
      [
        {
          name: PARK_TYPES.ROADSIDE,
          filterName: i18n.t('filter:categories:roadside'),
        },
        {
          name: PARK_TYPES.PLANAR,
          filterName: i18n.t('filter:categories:planar'),
        },
        {
          name: PARK_TYPES.MULTILEVEL,
          filterName: i18n.t('filter:categories:multilevel'),
        },
        {
          name: PARK_TYPES.COMMERCE,
          filterName: i18n.t('filter:categories:commerce'),
        },
        {
          name: PARK_TYPES.FREE,
          filterName: i18n.t('filter:categories:free'),
        },
        {
          name: PARK_TYPES.FORDISABLED,
          filterName: i18n.t('filter:categories:forDisabled'),
        },
        {
          name: PARK_TYPES.POCKET,
          filterName: i18n.t('filter:categories:pocket'),
        },
      ],
      [
        {
          name: PARK_TYPES.PARKOMAT,
          filterName: i18n.t('filter:categories:parkomat'),
        },
        {
          name: PARK_TYPES.PAYMENTTERMINAL,
          filterName: i18n.t('filter:categories:paymentTerminal'),
        },
        {
          name: PARK_TYPES.INFORMATIONTABLE,
          filterName: i18n.t('filter:categories:infoTable'),
        },
        {
          name: PARK_TYPES.SIGHT,
          filterName: i18n.t('filter:categories:sight'),
        },
        {
          name: PARK_TYPES.MUSEUM,
          filterName: i18n.t('filter:categories:museum'),
        },
        {
          name: PARK_TYPES.CHURCH,
          filterName: i18n.t('filter:categories:church'),
        },
      ],
    ];
  }
  //types = types
  get types() {
    return [i18n.t('filter:types:public'), i18n.t('filter:types:other')];
  }
  /**
   * 0 - Придорожные
   * 1 - Плоскостные
   * 2 - Коммерческие
   * 3 - Бесплатные
   * 4 - Для инвалидов
   * 5 - Карманы
   *
   * 0 - Паркоматы
   * 1 - Терминалы
   * 2 - Информационное табло
   * 3 - Достопримечательность
   * 4 - Музей
   * 5 - Церковь
   */
  // @observable activeCategories = [[0, 1, 2, 3, 4, 5, 6], [0, 1, 2, 3, 4, 5]];
  @observable activeCategories = [[0, 1, 2, 3, 4, 5, 6], [0, 1, 2, 3, 4, 5]];

  get data() {
    return this.data.features;
  }

  set data(data) {
    this.data = { ...data };
  }

  get status() {
    return this.status;
  }

  set status(status) {
    this.status = status;
  }

  @action
  loadData = async () => {
    this.objectIndexes = { ...this.defaultObjectIndexes };
    this.status = 'pending';

    try {
      if (this.isNeededToLoad()) {
        const data = await APIProvider.getMapInfoV3();
        this.setLastTimeMaploading();

        runInAction(() => {
          if (data && data.features) {
            // check for data is not empty
            this.data = { ...this.dataNormalize(data) };
          }
        });
      }
      this.getObjectIndexes(this.data.features);
      this.status = 'done';
      return this.data;
    } catch (e) {
      console.error(e);
      this.status = 'done';
      this.data = {
        overAll: 0,
      };
    }
  };

  getObjectIndexes(data) {
    const hasForDisabled = data.some(it => it.properties.category === PARK_TYPES.FORDISABLED);

    data.forEach((element) => {
      //если вдруг неожиданно в бекенде добавили новую категорию, а на фронте не описали ее
      if (this.objectIndexes[element.properties.category]) {
        this.objectIndexes[element.properties.category] = [
          ...this.objectIndexes[element.properties.category],
          element,
        ];
        if (!hasForDisabled && element.properties.spaces && element.properties.spaces.fordisabled) {

          this.objectIndexes[PARK_TYPES.FORDISABLED] = [
            ...this.objectIndexes[PARK_TYPES.FORDISABLED],
            // element,
          ];
        }
      } else {
        console.warn(`Отсутствует категория ${element.properties.category}`);
      }
    });
  }

  loadZonesIfNeeded() {
    if (!this.zones.done && !this.zones.isLoading) {
      this.zones.isLoading = true;

      this.loadZones();
    }
  }

  @action loadZones = async () => {
    this.zones.isLoading = true;

    try {
      const data = await APIProvider.getZones();
      if (data) {
        this.zones = {
          items: data,
          done: true,
          error: false,
          isLoading: false,
        };
      } else
        this.zones = {
          done: false,
          isLoading: false,
          error: true,
        };
    } catch (e) {
      console.error(e);
      this.zones.error = true;
    }
  };

  getZonesWithoutPlanar() {
    if (this.zones.hasOwnProperty('items')) return this.zones.items;

    return [];
  }

  getZones() {
    if (this.zones.hasOwnProperty('items')) return this.zones.items;

    return [];
  }

  @computed
  get sortedZones() {
    return this.getZones()
      .map((zone) => zone.name)
      .sort((a, b) => a.localeCompare(b));
  }

  @computed
  get getParkings() {
    if (this.data.features) {
      const parkings = [];

      this.data.features.forEach((item) => {
        if (item.properties.objecttype === OBJ_TYPES.PARKINGS) {
          parkings.push(item.properties);
        }
      });

      return parkings;
    }

    return [];
  }

  get times() {
    return this.data.times || null;
  }

  getParkingsWithoutFree() {
    return this.getParkings.filter((item) => {
      return (
        item.category !== PARK_TYPES.FREE &&
        item.category !== PARK_TYPES.FORDISABLED
      );
    });
  }

  getParkingsForStandartForm() {
    return this.getParkingsWithoutFree().filter(
      (parking) => parking.category !== PARK_TYPES.PLANAR
    );
  }

  getByZoneNumber(type, zone) {
    const isFeaturesExist = 'features' in this.data;
    let item;

    if (isFeaturesExist) {
      const features = this.data.features;
      for (let i = 0; i < features.length; ++i) {
        if (features[i].properties.objecttype === 'parcomats') continue;
        if (
          features[i].properties.objecttype &&
          features[i].properties.zone.zonenumber
        ) {
          item = features[i].properties;
          break;
        }
      }
    }
    return item;
  }

  dataNormalize = (data) => {
    let normalizeData = data.features.map((feature, index) => {
      const objecttype = feature.properties.objecttype;
      let properties = {};

      if (objecttype === 'parcomats') {
        properties = {
          ...feature.properties,
          category: 'Паркомат',
        };
      } else {
        const negativeDayCode = feature.properties.zone && feature.properties.zone.prices
          ? feature.properties.zone.prices.findIndex((schedule) => {
              return schedule.daycode === -2;
            })
          : -1;
        properties = {
          inactive: negativeDayCode !== -1,
          ...feature.properties,
        };
      }

      let coordinates = this.fixLatLng(
        feature.geometry.coordinates,
        feature.geometry.type
      );

      return {
        type: feature.type,
        properties: properties,
        geometry: {
          coordinates: coordinates,
          type: feature.geometry.type,
        },
      };
    });
    return {
      type: data.type,
      features: normalizeData,
      overAll: data.OverAll,
      times: data.times,
    };
  };

  fixLatLng = (coordinates, type) => {
    let newCoordinates = null;
    switch (type) {
      case 'LineString':
      case 'Polygon':
        newCoordinates = coordinates.map((item) => {
          return [item['latitude'], item['longitude']];
        });
        break;
      case 'Point':
        newCoordinates = [
          coordinates[0]['latitude'],
          coordinates[0]['longitude'],
        ];
        break;
      default:
    }
    return newCoordinates;
  };

  filterData = (data) => {
    let newData = {
      type: data ? data.type : '',
      features: [],
    };

    let currentCountOfObjects = {
      parkings: 0,
      places: 0,
      freePlaces: 0,
    };

    const hasForDisabled = data.features.some(it => it.properties.category === PARK_TYPES.FORDISABLED);
    const mustAddForDisabled = !hasForDisabled && this.activeCategories[0].includes(5);

    const getParkTypeNameFromIndex = (index) => {
      return Object.values(PARK_TYPES)[index];
    }

    if (data && data.features && data.features.length) {
      // check for data is not empty
      this.activeCategories.forEach((item, index) => {
        let featuresForDisabled = [];
        const activeCategoriesNames = this.activeCategories[0].filter(it => it !== 5).map(it => getParkTypeNameFromIndex(it));
        if (index === 0 && mustAddForDisabled) {
          featuresForDisabled = data.features.filter((feat) => {
            const featHasForDisabled = feat.properties.spaces && feat.properties.spaces.fordisabled !== 0;
            const isUsed = activeCategoriesNames.includes(feat.properties.category);
            return featHasForDisabled && !isUsed;
          });

        }

        item.forEach((category) => {
          const categoryName = this.categories[index][category].name;

          if (index === 0) {
            currentCountOfObjects.parkings += this.objectIndexes[
              categoryName
            ].length;

            this.objectIndexes[categoryName].forEach((item) => {
              currentCountOfObjects.places += item.properties.spaces.total;
              currentCountOfObjects.freePlaces += item.properties.spaces.free;
            });

            if (mustAddForDisabled && categoryName === PARK_TYPES.FORDISABLED) {
              // currentCountOfObjects.places = 66;
              let countParkingsForDisabled = 0;
              let countPlacesForDisabled = 0;
              data.features.forEach(it => {
                const featHasForDisabled = it.properties.spaces && it.properties.spaces.fordisabled !== 0;
                const isUsed = activeCategoriesNames.includes(it.properties.category);
                if (featHasForDisabled && !isUsed) {
                  countParkingsForDisabled++;
                  countPlacesForDisabled+=it.properties.spaces.fordisabled;
                }
              });
              currentCountOfObjects.parkings += countParkingsForDisabled;
              currentCountOfObjects.places += countPlacesForDisabled;
            }
          }

          let filteredFeatures = this.objectIndexes[categoryName];
          if (mustAddForDisabled && categoryName === PARK_TYPES.FORDISABLED) {
            filteredFeatures = featuresForDisabled;
          }

          newData.features = [
            ...newData.features,
            ...filteredFeatures,
            // ...this.objectIndexes[categoryName],
          ];
        });
      });
    }

    this.currentCountOfObjects = {
      ...currentCountOfObjects,
    };

    return newData;
  };

  isParkingFree(code) {
    const parking = this.findParking(code);

    return parking.spaces.free > 0;
  }

  findParking(code) {
    return this.getParkings.find((item) => {
      return item.code === code;
    });
  }

  addCarToParking(code) {
    const currentParking = this.findParking(code);
    currentParking.spaces.free--;
  }
}
export default new MapStore();
