const regFormEng = {
  stepsNames: {
    step: 'Step',
    registration: 'Sign Up',
    confirmEmail: 'Confirm email',
    confirmPhone: 'Confirm phone number'
  },
  seconds: {
    one: 'second',
    two: 'seconds',
    five: 'seconds'
  },
  steps: {
    step1: {
      formFields: {
        password: 'Password',
        confirmPassword: 'Confirm password',
        phone: 'Phone number',
        FirstName: 'First name',
        LastName: 'Last name',
        MiddleName: 'Patronymic'
      },

      agreeRules: 'I agree with the rules of processing personal data',
      iAgreeWith: 'I agree with',
      rulesForProcessingData: 'the rules of processing personal data',
      rulesForProcessingPersonalData: 'By registering on the site, you accept the "Rules for the processing of personal data"',

      familiarize: 'Review',

      errors: {
        emptyField: 'You need to fill in this field',
        incorrectEmail: 'Incorrect email address',
        userWithEmailExist: 'User with this email already exists',
        userWithPhoneExist: 'User with this phone number already exists',
        phoneInvalid: 'Invalid phone number',
        passwordLength: 'The password must be longer than 3 characters',
        confirmPassword: 'Repeat the password',
        passwordsNotMatch: 'The passwords you entered do not match',
        requiredField: 'This field is required',
        incorrectNumber: 'Incorrect phone number entered',
        userAgreement: 'To continue the registration you need to accept the user agreement',
        validateError: 'This field can not be validated',
        registrationError: 'Registration error. Please try again later.',
        unknownError: 'Unknown error',
        strongValidate: 'The password must be between 6 and 20 characters long',
        EmailExists: 'User with this email already exist',
        ValidationError: 'Validation error',
        PhoneExists: 'User with this phone already exist',
        PhoneEmpty: 'Phone cant be empty',
        SMSNotSend: 'Can not send SMS'
      }
    },
    step2: {
      formFields: {
        code: 'Code'
      },
      submitButton: 'Send code',
      waitingMessage: 'Please wait, the code is being checked',
      emailMessages: {
        activation: 'To log in click the link from the email or enter the code below',
        emailExist: {
          emailSent: 'Verification email is successfully sent to your email'
        },
        emailNotExist: {
          emailSent: 'Verification email was successfully sent to the email you specified'
        }
      },
      sendCodeAgain: {
        message: 'Send the code again in',
        button: 'Send the code again'
      },
      errors: {
        incorrectCode: 'The code is incorrect',
        incorrectPhoneNumber: 'Incorrect phone number entered during registration',
        enterCode: 'Please enter the code',
        SMSNotSend: 'Can not send SMS'
      }
    },
    step3: {
      formFields: {
        code: 'Code'
      },
      submitButton: 'Send code',
      phoneMessage: {
        smsSent: 'A text with the code is successfully sent',
        smsSentError: 'Failed to send SMS to number: {{number}}',
        callError: 'Failed to call number: {{number}}',
        enterBelow: 'Enter the code in the box below',
        callMade: 'Within a few minutes you will receive a call. Enter the last 4 digits of the incoming number in the field below',
      },
      waitingMessage: 'Please wait, checking the code',
      sendCodeAgain: {
        message: 'Reenter the code in',
        button: 'Reenter the code'
      },
      callAgain: {
        message: 'You can receive a call again through',
        button: 'Call again'
      },
      errors: {
        incorrectCode: 'The code is incorrect',
        enterCode: 'Please enter the code'
      }
    }
  }
};
const regFormRu = {
  stepsNames: {
    step: 'Шаг',
    registration: 'Регистрация',
    confirmEmail: 'Подтверждение адреса электронной почты',
    confirmPhone: 'Подтверждение номера телефона'
  },
  seconds: {
    one: 'секунду',
    two: 'секунды',
    five: 'секунд'
  },
  steps: {
    step1: {
      formFields: {
        password: 'Пароль',
        confirmPassword: 'Повторите пароль',
        phone: 'Номер телефона',
        FirstName: 'Имя',
        LastName: 'Фамилия',
        MiddleName: 'Отчество'
      },

      agreeRules: 'Я согласен с правилами обработки персональных данных',
      iAgreeWith: 'Я согласен с',
      rulesForProcessingData: 'правилами обработки персональных данных',
      rulesForProcessingPersonalData: 'Регистрируясь на сайте, вы принимаете "Правила обработки персональных данных"',

      familiarize: 'Ознакомиться',

      errors: {
        emptyField: 'Вам необходимо заполнить это поле',
        incorrectEmail: 'Введен некорректный E-mail адрес',
        userWithEmailExist: 'Пользователь с таким email уже существует',
        userWithPhoneExist: 'Пользователь с таким номером телефона уже существует',
        phoneInvalid: 'Неверный формат номера телефона',
        passwordLength: 'Длина пароля должна быть больше 3 символов',
        confirmPassword: 'Необходимо повторить пароль',
        passwordsNotMatch: 'Введенные пароли не совпадают',
        requiredField: 'Это поле обязательно для заполнения',
        incorrectNumber: 'Введен некорректный номер',
        userAgreement: 'Для продолжения регистрации Вам нужно принять пользовательское соглашение',
        validateError: 'Невозможно валидировать это поле',
        registrationError: 'Произошла ошибка при регистрации. Повторите попытку позднее',
        unknownError: 'Неизвестная ошибка',
        strongValidate: 'Длина пароля должа быть от 6 до 20 символов',
        EmailExists: 'Пользователь с таким email уже существует',
        ValidationError: 'Ошибка валидации',
        PhoneExists: 'Пользователь с таким телефоном уже существует',
        PhoneEmpty: 'Телефон обязателен к заполнению',
        SMSNotSend: 'Не удалось отправить SMS'
      }
    },
    step2: {
      formFields: {
        code: 'Код'
      },
      submitButton: 'Отправить',
      waitingMessage: 'Пожалуйста, подождите, мы проверяем Ваш код',
      emailMessages: {
        activation: 'Для активации, Вы можете перейти по ссылке в письме или ввести код ниже',
        emailExist: {
          emailSent: ' Письмо с подтверждением успешно отправлено на почту'
        },
        emailNotExist: {
          emailSent: 'Письмо с подтверждением успешно отправлено на указанную Вами почту'
        }
      },
      sendCodeAgain: {
        message: 'Отправить повторно вы сможете через',
        button: 'Отправить код снова'
      },
      errors: {
        incorrectCode: 'Неверный код',
        incorrectPhoneNumber: 'Введен некорректный номер телефона при регистрации',
        enterCode: 'Пожалуйста, введите код',
        SMSNotSend: 'Не удалось отправить SMS'
      }
    },
    step3: {
      formFields: {
        code: 'Код'
      },
      submitButton: 'Отправить',
      phoneMessage: {
        smsSent: 'СМС с кодом успешно отправлено на номер',
        smsSentError: 'Не удалось отправить СМС на номер: {{number}}',
        callError: 'Не удалось совершить звонок на номер: {{number}}',
        enterBelow: 'Вам необходимо ввести его в поле ниже',
        callMade: 'В течение нескольких минут вам поступит звонок. Введите последние 4 цифры входящего номера в поле ниже',
      },
      waitingMessage: 'Пожалуйста, подождите, мы проверяем Ваш код',
      sendCodeAgain: {
        message: 'Отправить повторно вы сможете через',
        button: 'Отправить код снова'
      },
      callAgain: {
        message: 'Получить звонок повторно вы сможете через',
        button: 'Позвонить снова'
      },
      sendReplaySmsCode: {
        message: 'Если не поступил звонок, отправить SMS с кодом можно через',
        button: 'Отправить SMS'
      },

      errors: {
        incorrectCode: 'Неверный код'
      }
    }
  }
};

export default {
  eng: regFormEng,
  ru: regFormRu
};
