import React, { useEffect, useState } from "react";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import SpinnerButton from "../../../../Common/Buttons/SpinnerButton";
import AutoSubmit from "../../../../Common/Inputs/AutoSubmit";
import { Col, Row } from "reactstrap";

const ReceiptLegal = (props) => {
  const [name, setName] = useState('');
  const [ogrn, setOgrn] = useState('');
  const [inn, setInn] = useState('');
  const [kpp, setKpp] = useState('');
  const [address, setAddress] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [ogrnDirty, setOgrnDirty] = useState(false);
  const [innDirty, setInnDirty] = useState(false);
  const [kppDirty, setKppDirty] = useState(false);
  const [addressDirty, setAddressDirty] = useState(false);
  const [nameError, setNameError] = useState('Необходимо заполнить');
  const [ogrnError, setOgrnError] = useState('Необходимо заполнить');
  const [innError, setInnError] = useState('Необходимо заполнить');
  const [kppError, setKppError] = useState('Необходимо заполнить');
  const [addressError, setAddressError] = useState('Необходимо заполнить');
  const [formValid, setFormValid] = useState(false);

  // console.log(">>> Legal props", props);

  useEffect(
    () => {
      if (nameError || ogrnError || innError || kppError || addressError) {
        setFormValid(false);
      } else {
        setFormValid(true);
      }
    }, [
      nameError,
      ogrnError,
      innError,
      kppError,
      addressError
    ])

  const nameHandler = (e) => {
    setName(e.target.value);
    if(!e.target.value.length) {
      setNameError('Необходимо заполнить');
    } else {
      setNameError('');
    }
  }

  const ogrnHandler = (e) => {
    setOgrn(e.target.value);
    const re  = /^\d+$/
    if (!re.test(String(e.target.value)) || (e.target.value.length != 13)) {
      setOgrnError('Только 13 цифр');
    } else {
      setOgrnError('');
    }
  }

  const innHandler = (e) => {
    setInn(e.target.value);
    const re  = /^\d+$/
    if (!re.test(String(e.target.value)) || (e.target.value.length != 10)) {
      setInnError('Только 10 цифр');
    } else {
      setInnError('');
    }
  }

  const kppHandler = (e) => {
    setKpp(e.target.value);
    const re  = /^\d+$/
    if (!re.test(String(e.target.value)) || (e.target.value.length != 9)) {
      setKppError('Только 9 цифр');
    } else {
      setKppError('');
    }
  }

  const addressHandler = (e) => {
    setAddress(e.target.value);
    if(!e.target.value.length) {
      setAddressError('Необходимо заполнить');
    } else {
      setAddressError('');
    };
  }

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name':
        setNameDirty(true);
        break;
      case 'ogrn':
        setOgrnDirty(true);
        break;
      case 'inn':
        setInnDirty(true);
        break;
      case 'kpp':
        setKppDirty(true);
        break;
      case 'address':
        setAddressDirty(true);
        break;
    }
  }

  return (
    <>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="name">ПОЛНОЕ НАИМЕНОВАНИЕ:
              { (nameDirty && nameError) && <span className="pl-2" style={{color:'red'}}>{nameError}</span> }
            </label>
            <input
              defaultValue={name}
              onChange={e => nameHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="name"
              type="text"
              name="name"/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="ogrn">ОГРН:
              { (ogrnDirty && ogrnError) && <span style={{color:'red'}}>{ogrnError}</span> }
            </label>
            <input
              defaultValue={ogrn}
              onChange={e => ogrnHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="ogrn"
              type="text"
              name="ogrn"/>
          </Col>
          <Col>
            <label className="w-100 form-label" htmlFor="inn">ИНН:
              { (innDirty && innError) && <span style={{color:'red'}}>{innError}</span> }
            </label>
            <input
              defaultValue={inn}
              onChange={e => innHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="inn"
              type="text"
              name="inn"/>
          </Col>
          <Col>
            <label className="w-100 form-label" htmlFor="kpp">КПП:
              { (kppDirty && kppError) && <span style={{color:'red'}}>{kppError}</span> }
            </label>
            <input
              defaultValue={kpp}
              onChange={e => kppHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="kpp"
              type="text"
              name="kpp"/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <label className="w-100 form-label" htmlFor="address">Юридический АДРЕС:
              { (addressDirty && addressError) && <span className="pl-2" style={{color:'red'}}>{addressError}</span> }
            </label>
            <input
              defaultValue={address}
              onChange={e => addressHandler(e)}
              onBlur={e => blurHandler(e)}
              className="w-100 form-control"
              id="address"
              type="text"
              name="address" />
          </Col>
        </Row>
      </FormGroup>
      <input type="hidden" value="2" name="type"/>
      <input type="hidden" value={props.amount} name="sum"/>
      <input type="hidden" value={props.codeSubscription} name="codeSubscription"/>
      <input type="hidden" value={props.grz} name="grz"/>
      <FormGroup>
        <Row>
          <Col
            className={"text-center"}
          >
            <Button
              disabled={!formValid}
              color="primary"
              type="submit"
              className="submit"
              style={{ appearance: 'none' }}
            >
              Далее
            </Button>
          </Col>
          <Col
            className={"text-center"}
          >
            <Button
              outline
              color="primary"
              onClick={props.onAbort}
              className="cancel"
            >
              {props.t('operations:annulment')}
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </>
  );
}

export default ReceiptLegal;