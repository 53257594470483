export const INTERVALS = 'Интервалы';
export const USE_TS_CATEGORY = 'ИспользоватьКатегорииТС';
export const USE_FLAT_PARKING = 'ИспользоватьПлоскостныеПарковки';
export const USE_POST_PAYMENT = 'ИспользоватьПостОплату';
export const USE_POST_PAYMENT_YESTERDAY = 'ИспользоватьПостоплатуЗаПредыдущийДень';
export const USE_SESSION_WITHOUT_REGISTER = 'ИспользоватьОплатуБезРегистрации';
export const USE_BONUS = 'ИспользоватьБонусы';
export const VIEW_PENALTY = 'ВключитьПросмотрШтрафов';
export const PAYMENT_BY_PARKING_ID = 'ОплатаВРазрезеПарковок';
export const PAYMENTS_METHODS = 'СпособыОплаты';
export const METHOD_NAME = 'НаименованиеДляВозврата';
export const METHOD_NAME_FOR_SHOW = 'НаименованиеДляОтображения';
export const METHOD_DESCRIPTION = 'ОписаниеНаПортале';
export const IS_PHONE_REQUIRED = 'ТребуетсяНомерТелефона';
export const MIN = 'Мин';
export const MAX = 'Макс';
export const STEP = 'Шаг';
export const DONT_USE_BALANCE = 'НеИспользоватьБаланс';
export const TOPICS = 'ТемыОбращений';
export const TOPIC_NAME = 'Наименование';
export const TOPIC_CODE = 'Код';
export const USE_RECEIPTS = 'ИспользоватьКвитанции';

const constants = {
  INTERVALS,
  USE_TS_CATEGORY,
  USE_FLAT_PARKING,
  USE_POST_PAYMENT,
  USE_POST_PAYMENT_YESTERDAY,
  USE_SESSION_WITHOUT_REGISTER,
  PAYMENT_BY_PARKING_ID,
  PAYMENTS_METHODS,
  METHOD_NAME,
  METHOD_NAME_FOR_SHOW,
  METHOD_DESCRIPTION,
  IS_PHONE_REQUIRED,
  MIN,
  MAX,
  STEP,
  DONT_USE_BALANCE,
  TOPICS,
  TOPIC_NAME,
  TOPIC_CODE,
  USE_BONUS,
  VIEW_PENALTY,
  USE_RECEIPTS,
};

export default constants;
