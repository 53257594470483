const parkingEng = {
  parking: 'Parking',
  currentParkings: {
    title: 'Current parking sessions',
    paymentTitle: 'Parking payment',
    renewTitle: 'Parking prolongation',
    refill: 'Top up the balance',
    replenishment: 'Top Up',

    labels: {
      amount: 'Total',
      zone: 'Zone',
      parking: 'Parking',
      vehicle: 'Vehicle',
      duration: 'Duration',
      paymentMethods: 'Payment methods',
      phoneNumber: 'Phone number'
    },

    smsMessage:
      'A text with further instructions on how to complete the payment' +
      'is sent to the indicated phone number.',
    redirectMessage:
      'You will be redirected to the payment system page,' + 'where you can make the payment.',
    noParkingMessage: 'At the moment you have no active parking sessions.',

    amountToPaid: 'Amount due',
    fromAccount: 'from the account',
    toDeposite: 'Amount to deposite: ',
    selectedWay: 'selected payment method',

    remaining: {
      now: 'Ends',
      todayAt: 'Ends today at',
      at: 'at'
    },
    startedAt: 'Session started at',
    paymentMethods: {
      mobilePhone: 'Mobile phone',
      bankCard: 'Card',
      yandexMoney: 'Yandex money'
    },
    commissions: {
      without: 'No comission'
    }
  },

  payments: {
    title: 'Pay for parking',
    labels: {
      zone: 'Zone',
      parking: 'Parking',
      perHour: 'per hour',
      formTo: 'Mon-Fri',
      vehicleNumber: 'Vehicle license plate',
      duration: 'Duration',
      cardNumber: 'Card number'
    },
    costParking: 'Parking Cost',
    costPerHour: 'Session per hour costs',
    privilegeApplied: 'Privilege applied',
    privilegesApplied: 'Privileges applied',
    saved: 'Saved',
    insufficientFunds: 'You need to replenish the balance in the amount of ',
    because: ', since on your wallet ',
    pressContinueMessage:
      'Press "Continue",\n' +
      'to select the payment method and the amount' +
      'required to proceed the payment.',
    parkingZones: {
      roadSide: 'On-street parking',
      flatBarrier: 'Off-street parking'
    },

    paymentTypes: {
      today: 'From now',
      postPayment: 'Postpay for the current day',
      postPaymentYesterday: 'Postpay for the yesterday'
    },

    timePicker: {
      placeholder: 'Select time',
      label: 'Time of begin'
    },

    intervals: {
      period: 'Period',
      cost: 'Cost',
      benefit: 'Benefit',
      percents: 'percents',
      no: 'No'
    },

    postPaymentMessages: {
      first: 'You can pay for parking after its completion.',
      second:
        'If you are not sure of the exact time of parking, in order to avoid the' +
        ' application of penalties, it is recommended to call a obviously longer period of payment.',
      third: 'Warning! You can pay only during the current day (until 23:59).'
    },

    postPaymentYesterdayMessages: {
      first: 'You can pay for parking after its completion.',
      second:
        'If you are not sure of the exact time of parking, in order to avoid the' +
        ' application of penalties, it is recommended to call a obviously longer period of payment.',
      third: 'Warning! You can pay only during the next day (until 23:59).'
    },

    errors: {
      NoDurationSelected: 'Select the duration',
      NoZoneSelected: 'Select the parking zone',
      NoSumEntered: 'Enter the top up amount',

      UnknownError: 'An unknown error. Try again. ',
      ValidationError: 'Data verifying error. ' + 'Verify the data you entered.',
      TokenNotFound: 'Problems with your session. ' + 'Try logging out and in again.',
      AccountNotFound: 'The account connected with this phone number is not found.',
      ParkingSessionIsOver:
        'The parking session has already been completed. ' +
        'It is not possible to extend / cancel the session.',
      ZoneNotFound: 'The selected zone is not found.',
      ParkingSessionNotFound: 'The parking session is not found.',
      TariffNotFound: 'The zone tariff is not found.',
      ParkingSessionAlreadyExists: 'The parking session for this' + 'vehicle already exists.',
      NoSaving: 'The remaining time is less than 30 minutes. No cancelling. ',
      PaymentServiceNotFound:
        'The selected payment method is not' + 'supported or temporarily unavailable.',
      CalculationCostError: 'Unable to calculate the session cost.',
      NoMoneyNoHoney: 'Not sufficient funds to proceed the payment.',
      NotSelectedPaymentService: 'Not sufficient funds. ' + 'Select the payment method to top up.'
    }
  }
};

const parkingRu = {
  parking: 'Парковки',
  defferedParkings: {
    title: 'Отложенные парковки',
    noParkingMessage: 'На данный момент у Вас нет отложенных парковок.'
  },
  currentParkings: {
    title: 'Текущие парковки',
    paymentTitle: 'Оплата парковки',
    renewTitle: 'Продление парковки',
    refill: 'Пополнение баланса',
    replenishment: 'Пополнение',

    labels: {
      amount: 'Сумма',
      zone: 'Зона',
      parking: 'Парковка',
      vehicle: 'Транспортное средство',
      duration: 'Продолжительность',
      paymentMethods: 'Методы оплаты',
      phoneNumber: 'Номер телефона'
    },

    smsMessage:
      'На указанный номер будет отправлено СМС-сообщение' +
      ' с дальнейшими инструкциями для завершения оплаты.',
    redirectMessage:
      'Вы будете перенаправлены на страницу платёжной системы, ' + 'где сможете произвести оплату.',
    noParkingMessage: 'На данный момент у Вас нет активных парковок.',

    amountToPaid: 'Сумма к оплате',
    fromAccount: 'со счета',
    toDeposite: 'Сумма к пополнению: ',
    selectedWay: 'выбранным способом',

    remaining: {
      now: 'Истекает',
      todayAt: 'Истекает сегодня в',
      at: 'в'
    },
    startedAt: 'Начало сессии',
    paymentMethods: {
      mobilePhone: 'Мобильный телефон',
      bankCard: 'Банковская карта',
      yandexMoney: 'Яндекс деньги'
    },
    commissions: {
      without: 'Без комиссии'
    }
  },

  payments: {
    title: 'Начать парковку',
    labels: {
      zone: 'Зона',
      parking: 'Парковка',
      perHour: 'в час',
      formTo: 'Пн-Пт',
      vehicleNumber: 'Номер ТС',
      duration: 'Длительность',
      cardNumber: 'Номер карты',
      noTs: 'Нет свободных авто'
    },
    privilegeApplied: 'Применена льгота',
    privilegesApplied: 'Применены льготы',
    saved: 'Экономия',
    insufficientFunds: 'Вам необходимо пополнить баланс на сумму',
    because: ', так как на Вашем счете',
    pressContinueMessage:
      'Нажмите на кнопку "Продолжить",\n' +
      'чтобы выбрать способ оплаты и сумму,\n' +
      'недостающую для оплаты парковки.',
    costParking: 'Стоимость парковки',
    costPerHour: 'Стоимость парковочного часа',
    parkingZones: {
      roadSide: 'Придорожная',
      flatBarrier: 'Плоскостная со шлагбаумом'
    },
    paymentTypes: {
      today: 'С текущего момента',
      postPayment: 'Постоплата за текущие сутки',
      postPaymentYesterday: 'Постоплата за предыдущие сутки'
    },

    timePicker: {
      placeholder: 'Выберите время',
      label: 'Время начала парковки'
    },

    intervals: {
      period: 'Период',
      cost: 'Стоимость',
      benefit: 'Льгота',
      percents: 'процентов',
      no: 'Нет'
    },

    postPaymentMessages: {
      first: 'Вы можете начать парковку после ее завершения.',
      second:
        'Если вы не уверены в точном времени парковки, во избежание применения' +
        ' штрафных санкций рекомендуется прозвести оплату заведомо более длительного периода.',
      third: 'Внимание! Произвести оплату можно только в течение текущих суток (до 23:59).'
    },

    postPaymentYesterdayMessages: {
      first: 'Вы можете начать парковку после ее завершения.',
      second:
        'Если вы не уверены в точном времени парковки, во избежание применения' +
        ' штрафных санкций рекомендуется прозвести оплату заведомо более длительного периода.',
      third: 'Внимание! Произвести оплату можно только в течение последующих суток (до 23:59).'
    },

    errors: {
      NoDurationSelected: 'Необходимо выбрать продолжительность.',
      NoZoneSelected: 'Необходимо выбрать парковочную зону.',
      NoSumEntered: 'Необходимо ввести сумму пополнения.',

      UnknownError: 'Произошла неизвестная ошибка. Попробуйте повторить попытку.',
      ValidationError:
        'Произошла ошибка при проверке данных. ' + 'Проверьте корректность введенных Вами данных.',
      TokenNotFound:
        'Возникли проблемы с Вашей сессией. ' +
        'Попробуйте выйти из аккаунта и заново авторизоваться.',
      AccountNotFound: 'Не удалось найти аккаунт с введенным Вами номером телефона.',
      ParkingSessionIsOver:
        'Парковочная сессия уже завершилась. ' + 'Не возможно продлить / прервать сессию.',
      ZoneNotFound: 'Не удалось найти выбранную Вами зону.',
      ParkingSessionNotFound: 'Не удалось найти парковочную сессию.',
      TariffNotFound: 'Не удалось найти тариф для зоны.',
      ParkingSessionAlreadyExists:
        'Парковочная сессия для этого ' + 'транспортного средства уже существует.',
      NoSaving: 'Осталось меньше получаса. Невозможно прервать.',
      PaymentServiceNotFound:
        'Выбранный метод оплаты не ' + 'поддерживается или временно недоступен.',
      CalculationCostError: 'Не удалось расчитать стоимость сессии.',
      NoMoneyNoHoney: 'Не достаточно средств для оплаты парковочной сессии.',
      NotSelectedPaymentService:
        'На балансе недостаточно средств. ' + 'Необходимо выбрать способ пополнения.'
    }
  }
};

export default {
  eng: parkingEng,
  ru: parkingRu
};
