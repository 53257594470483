import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import { Col, Row } from 'reactstrap';

import ParkingCategory from '../../constants/parkTypes';
import FreePlacesRow from './freePlasecRow';

import SettingsStore from '../../Stores/SettingsStore';
import ReservationStore from '../../Stores/ReservationStore';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faClock from '@fortawesome/fontawesome-free-solid/faClock';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import angleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import angleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import sortUp from '@fortawesome/fontawesome-free-solid/faSortUp';
import sortDown from '@fortawesome/fontawesome-free-solid/faSortDown';
import mapMarkerAlt from '@fortawesome/fontawesome-free-solid/faMapMarkerAlt';
import rubleSign from '@fortawesome/fontawesome-free-solid/faRubleSign';
import wheelChair from '@fortawesome/fontawesome-free-solid/faWheelchair';

import './style.css';

@translate(['reservation'], { wait: true })
@observer
class Reservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationFullView: false,
      arrowIcon: { name: 'angle-up', icon: angleUp },
      dropDownTimesVisible: false,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      dropDownTimesVisible: false,
    });
  }

  updateWindowDimensions() {
    if (window.innerWidth > 767) {
      this.setState({
        reservationFullView: true,
      });
    }
  }

  render() {
    const { reservationVisibility } = this.props.UIStore;
    let block =
      this.props.parking.objecttype === 'parkings'
        ? this.renderParking()
        : this.renderParcomat();

    return reservationVisibility ? (
      <Fragment>
        {this.renderHeader()}
        {block}
      </Fragment>
    ) : null;
  }

  renderHeader = () => {
    let arrow =
      this.props.parking.objecttype === 'parkings' ? (
        <div className={'reservation-arrow'} onClick={this.handleArrowClick}>
          <FontAwesomeIcon icon={this.state.arrowIcon.icon} />
        </div>
      ) : null;
    return (
      <div className="reservation-head">
        {arrow}
        <div className={'reservation-cross'} onClick={this.handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    );
  };

  renderImage() {
    const { linkpicture } = this.props.parking;
    if (linkpicture) {
      return (
        <a href={linkpicture} target="_blank">
          <img className="reservation__image" src={linkpicture}></img>
        </a>
      );
    }

    return null;
  }

  renderParcomat = () => {
    const { t } = this.props;
    const { name, address, description } = this.props.parking;
    return (
      <Fragment>
        <div className="reservation-zone">{name}</div>
        <div className="reservation-title-main">
          {description ? description : t('reservation:parkomat:title') }
        </div>
        <div className="res-rows">
          <span className="reservation-icon">
            <FontAwesomeIcon icon={mapMarkerAlt} />
          </span>
          <span className="reservation-title">{address}</span>
          {this.renderImage()}
        </div>
      </Fragment>
    );
  };

  renderParking = () => {
    let renderFullView = this.state.reservationFullView
      ? this.renderOptions()
      : null;

    return (
      <div className="reservation__inner">
        {this.renderHead()}

        {renderFullView}
      </div>
    );
  };

  renderHead = () => {
    const { zonetype } = this.props.parking.zone;
    const { t, parking } = this.props;

    const title = SettingsStore.settings.paymentByParkingId
      ? parking.name
      : parking.zone.zonename;

    return (
      <div className="reservation-body">
        <div>
          <div className="reservation-zone">{title}</div>
          <div className="reservation-title-main">
            {t(`reservation:type:${zonetype}`)}
          </div>
        </div>
      </div>
    );
  };

  handleArrowClick = () => {
    let arrowIcon =
      this.state.arrowIcon.name === 'angle-up'
        ? { name: 'angle-down', icon: angleDown }
        : { name: 'angle-up', icon: angleUp };
    this.setState({
      reservationFullView: !this.state.reservationFullView,
      arrowIcon: arrowIcon,
    });
  };

  handleClose = () => {
    this.setState((state) => {
      return {
        reservationFullView: !this.state.reservationFullView,
        arrowIcon: { name: 'angle-down', icon: angleDown },
      };
    });
    this.props.handleClose();
  };

  renderOptions = () => {
    const { category, spaces, zone, address } = this.props.parking;
    const { total, free, fordisabled } = spaces;
    const { t, parkingOperator } = this.props;
    let timesAndPrices = ReservationStore.getTimesAndPrices(zone.prices);
    const isScheduleHidden =
      category === ParkingCategory.FORDISABLED ||
      category === ParkingCategory.FREE ||
      category === ParkingCategory.PLANAR;


    return (
      <div className="reservation-body">
        <div className="reservation-body-inner">
          <div className="reservation-options-line">
            <span> {t(`reservation:category:${category}`)}</span>
          </div>
          <Row noGutters className="res-rows">
            <div className="reservation-icon">
              <FontAwesomeIcon icon={mapMarkerAlt} />
            </div>
            <div className="reservation-title">{address}</div>
          </Row>
          {this.timeOption(isScheduleHidden)}
          {isScheduleHidden
            ? null
            : this.renderDropDownTimes(timesAndPrices.times)}
          <Row noGutters className="res-rows">
            <FreePlacesRow
              category={category}
              total={total}
              free={free}
              t={t}
            />
          </Row>
          {fordisabled !== 0  ? (
            <Row noGutters className="res-rows">
              <div className="reservation-icon">
                <FontAwesomeIcon icon={wheelChair} />
              </div>
              <div className="reservation-title">
                <span>{`${t(
                  'reservation:parking:places:forDisabled'
                )}: ${fordisabled}`}</span>
              </div>
            </Row>
          ) : null}

          {this.renderPrice()}
          {parkingOperator ? (
            <Row noGutters className="res-rows">
              <span>{t(`reservation:parkingOperator:${parkingOperator}`)}</span>
            </Row>
          ) : null}
          {this.renderImage()}
        </div>
      </div>
    );
  };

  timeOption(isHidden) {
    if (isHidden) {
      return null;
    }

    const { zone } = this.props.parking;

    const timesAndPrices = ReservationStore.getTimesAndPrices(zone.prices);
    const dropDownIcon = this.state.dropDownTimesVisible
      ? { name: 'sort-up', icon: sortUp }
      : { name: 'sort-down', icon: sortDown };

    return (
      <Row noGutters className="res-rows">
        <div className="reservation-icon">
          <FontAwesomeIcon icon={faClock} />
        </div>
        <div className={`reservation-title ${isHidden ? 'hide-schedule' : ''}`}>
          {` ${timesAndPrices.currentWorkTime.timeStart} -
          ${timesAndPrices.currentWorkTime.timeEnd}`}
          <FontAwesomeIcon
            className={'dropdown-arrow'}
            icon={dropDownIcon.icon}
            onClick={() => {
              this.setState({
                dropDownTimesVisible: !this.state.dropDownTimesVisible,
              });
            }}
          />
        </div>
      </Row>
    );
  }

  renderPrice() {
    const {
      parking: { zone, category },
    } = this.props;
    const timesAndPrices = ReservationStore.getTimesAndPrices(zone.prices);

    if (
      category !== ParkingCategory.FREE &&
      category !== ParkingCategory.FORDISABLED &&
      category !== ParkingCategory.PLANAR
    )
      return (
        <Row noGutters className="res-rows">
          <div className="reservation-icon">
            <FontAwesomeIcon icon={rubleSign} />
          </div>
          <div className="reservation-title">{timesAndPrices.currentPrice}</div>
        </Row>
      );

    return null;
  }

  renderDropDownTimes = (times) => {
    const { t, parking } = this.props;
    const { reservationFullView } = this.state;
    const category = parking.category;
    return this.state.dropDownTimesVisible ? (
      <Row
        noGutters
        className={'dropdown-times-container'}
        style={reservationFullView ? { left: 0 } : null}
      >
        <Col className={'dropdown-times'} xs={{ size: 12 }}>
          {times.map((time, index) => {
            const price =
              category === ParkingCategory.FORDISABLED && ParkingCategory.FREE
                ? ''
                : `| ${time.price}`;

            return (
              <Row noGutters key={index}>
                <Col xs={7}>
                  {` ${time.timeStart} -
                      ${time.timeEnd} ${price}`}
                </Col>
                <Col xs={5}>{t(`reservation:days:${time.day}`)}</Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    ) : null;
  };
}

export default Reservation;
