import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable, toJS } from 'mobx';

import AbonementsContainer from '../../../Containers/AbonementsContainer';
import { SectionBlock } from '../../../Components/ProfileComponents';
import { Row, Col } from 'reactstrap';
import FieldPreLoader from '../../../Components/FieldPreLoader';
import PageWrapper from '../../../Components/ProfileComponents/Common/PageWrapper';

import UserStore from '../../../Stores/UserStore';

import { translate } from 'react-i18next';
import moment from 'moment';

import { CITY_NAME } from '../../../Config';

@translate(['operationsHistory'], { wait: true })
@observer
export default class Abonements extends Component {
  @observable fetchDataError = false;
  @observable allDataLoaded = false;
  @observable benefits = [];

  @observable tableWidth = '1px';

  async UNSAFE_componentWillMount() {
    await this._fetchData();
  }

  componentDidMount() {
    this._calculateTableWidth();
    window.addEventListener('resize', this._calculateTableWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._calculateTableWidth);
  }

  render() {
    const { t } = this.props;
    if (UserStore.benefits.error) {
      return this._renderError();
    }

    return (
      <Fragment>
        <PageWrapper>
          <SectionBlock
            title={t('abonements:benefitsTitle')}
            isLoading={!UserStore}
          >
            <div className="main-wrapper">{this._renderContent()}</div>
          </SectionBlock>
        </PageWrapper>
        {/* <PageWrapper>
          <SectionBlock
            title={t('abonements:subscriptionsTitle')}
            isLoading={!UserStore}
          >
            <AbonementsContainer t={t} />
          </SectionBlock>
        </PageWrapper> */}
      </Fragment>
    );
  }

  _renderError = () => {
    const { t } = this.props;

    return (
      <Row
        noGutters
        className="profile-abonements justify-content-center"
        style={{ flex: 1 }}
      >
        <Col md={12} lg={8} className="main-wrapper">
          <div className="md-card">
            <span>{t('abonements:errors:error')}</span>

            <div className="text-secondary">
              {t('abonements:errors:loadingDataError')}
            </div>

            <div className="error-buttons">
              <button
                style={{
                  fontSize: 14,
                  margin: 0,
                }}
                onClick={this._fetchData}
                className="menu-login btn btn-primary btn-small-round logout"
              >
                {t('abonements:tryAgain')}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  _renderContent = () => {
    const { t } = this.props;
    if (!UserStore.benefits.done) {
      return (
        <Fragment>
          {[1, 2, 3].map((element) => {
            return (
              <Row
                noGutters
                key={'holder-' + element}
                style={{ marginLeft: -10 }}
              >
                <Col xs={3}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={1}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={2}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={2}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={4}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
              </Row>
            );
          })}
        </Fragment>
      );
    }

    if (!UserStore.getBenefits() || UserStore.getBenefits().length === 0) {
      return <span>{t('abonements:errors:benefitsIsEmpty')}</span>;
    }

    const isRostov = CITY_NAME === 'rostov';
    const zoneName = isRostov
      ? t('abonements:tableLabels:cadastralQuarter')
      : t('abonements:tableLabels:zone');

    return (
      <div className="table-responsive" style={{ width: this.tableWidth }}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t('abonements:tableLabels:permissionType')}</th>
              <th scope="col">{zoneName}</th>
              <th scope="col">{t('abonements:tableLabels:vehicle')}</th>
              {isRostov ? null : (
                <th scope="col">
                  {t('abonements:tableLabels:discountPercentage')}
                </th>
              )}
              <th scope="col">{t('abonements:tableLabels:period')}</th>
            </tr>
          </thead>

          <tbody>{this._renderTableBody()}</tbody>
        </table>
      </div>
    );
  };

  getZones(zones) {
    const { t } = this.props;
    const jsZones = toJS(zones);

    if (Array.isArray(jsZones)) return `#${zones.join(', ')}`;

    return t('operationsHistory:operationType:all');
  }

  _renderTableBody = () => {
    const { t } = this.props;
    const isRostov = CITY_NAME === 'rostov';
    return UserStore.getBenefits().map((benefit, index) => {
      return (
        <tr key={'tr' + index}>
          <td>{benefit.variation}</td>
          <td>{this.getZones(benefit.zone)}</td>
          <td>{benefit.transport.numberTs}</td>
          {isRostov ? null : <td>{benefit.sale}</td>}
          <td>
            <div>
              <span>{t('abonements:table:start')}: </span>
              <span className="text-secondary">{benefit.startOf}</span>
            </div>

            <div>
              <span>{t('abonements:table:end')}: </span>
              <span className="text-secondary">{benefit.endOf}</span>
            </div>
          </td>
        </tr>
      );
    });
  };

  _fetchData = async () => {
    UserStore.loadBenefitsIfNeeded();
  };

  _transformData = (benefits) => {
    if (!benefits || benefits.length === 0) {
      this.benefits = [];

      return;
    }

    this.benefits = benefits
      .map((benefit) => {
        if (
          !benefit.hasOwnProperty('ВидРазрешения') ||
          !benefit.hasOwnProperty('ПроцентСкидки')
        ) {
          return null;
        }

        try {
          return {
            type: benefit['ВидРазрешения'],
            zone: benefit['Зона'],
            sale: benefit['ПроцентСкидки'],
            transport: this._transformTS(benefit['ТС']),

            startOf: moment(benefit['НачалоДействия']).format('DD/MM/YY HH:mm'),

            endOf: moment(benefit['ОкончаниеДействия']).format(
              'DD/MM/YY HH:mm'
            ),
          };
        } catch (e) {
          return null;
        }
      })
      .filter((a) => !!a);
  };

  _calculateTableWidth = () => {
    let width = document.body.clientWidth;

    if (width < 540) {
      width -= 30; // card margin
      width -= 30; // card padding

      this.tableWidth = width + 'px';
    } else {
      this.tableWidth = '100%';
    }
  };

  _transformTS = (number) => {
    return String(number)
      .replace(/\s/g, '')
      .replace(/^([A-zА-я])(\d{3})([A-zА-я]{3})(\d{2,3})$/, '$1 $2 $3 $4')
      .toUpperCase();
  };

  _sleep = (ms = 1500) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };
}
