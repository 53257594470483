import React, { Component, Fragment } from 'react';
import Link from '../../../Links/LngLink';

import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { translate } from 'react-i18next';

import UIStore from '../../../../Stores/UIStore';
import PathStore from '../../../../Stores/PathStore';
import LoginStore from '../../../../Stores/LoginStore';
import ProfileParkingStore from '../../../../Stores/ProfileParkingStore';

import { Row, Col, Button, Modal } from 'reactstrap';
import Input from '../../../../Components/MaterialDesign/Input';
import Loader from '../../../../Components/Loader';
import Captcha from '../../../../Components/Captcha';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import signInAlt from '@fortawesome/fontawesome-free-solid/faSignInAlt';
import OptionsStore from '../../../../Stores/OptionsStore';

import { IS_EASY_LOGIN } from '../../../../Config';

@translate(['loginForm'], { wait: true })
@observer
class StandartLogin extends Component {
  emailInput = React.createRef();
  easyLogin = React.createRef();
  passwordInput = React.createRef();
  captchaInput = null;
  isEasyLogin = IS_EASY_LOGIN;

  @observable _formSubmitted = false;
  @observable _isLoading = false;
  @observable UIStore;

  render() {
    const Content = this.modalContent();
    // eslint-disable-next-line
    let f = LoginStore.captchaBase64;
    return (
      <Modal
        className="modal-login"
        isOpen={UIStore.loginModal}
        onClosed={() => {
          this._isLoading = false;
        }}
      >
        <Content />
      </Modal>
    );
  }

  modalContent = () => {
    const { status, t } = this.props;

    if (status !== 'error' && this._isLoading) {
      return () => {
        return (
          <Fragment>
            <span className="loading-title">{t('loginForm:entering')}</span>
            <Loader />
          </Fragment>
        );
      };
    }

    return () => {
      return (
        <Fragment>
          {this.renderHeader()}
          {this.renderBody()}
        </Fragment>
      );
    };
  };

  renderHeader() {
    const { t } = this.props;

    return (
      <Row className="modal-login__header">
        <Col className="modal-login__enter" xs={10}>
          {t('loginForm:login')}
        </Col>
        <Col className="modal-login__close" xs={2}>
          <FontAwesomeIcon icon={faTimes} onClick={this.handleClose} />
        </Col>
      </Row>
    );
  }

  renderBody() {
    const { t, error } = this.props;
    const errorText = this.error(error);
    return (
      <Row noGutters className="justify-content-center align-items-center">
        <Col xs={12} className="login__container">
          <form className="form-horizontal" onSubmit={this.handleSubmit}>
            <fieldset>
              <Row className="login__error justify-content-center">
                <Col xs={11}>{errorText}</Col>
              </Row>
              {this.renderLoginInput()}
              <Input
                label={t('loginForm:password')}
                type="password"
                name="pass"
                id="pass-login"
                passwordEye
                ref={this.passwordInput}
                onBlur={this.handleBlur.bind(this, 'pass')}
              />
              {LoginStore.captchaBase64 ? (
                <Row>
                  <Col>
                    <Row>
                      <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Captcha value={LoginStore.captchaBase64} />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Input
                          label={t('loginForm:captcha')}
                          type="captcha"
                          name="captcha"
                          onChange={event => {
                            this.captchaInput = event.target.value;
                          }}
                          id="captcha"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              <div className=" modal-login__footer ">
                <div className="modal-login__footer-inner">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-small-round modal-login__submit"
                  >
                    {this.buttonIcon()}
                    <span className="modal-login__submit_inner">{t('loginForm:login')}</span>
                  </Button>
                  <Link
                    className="btn-registration"
                    style={{ backgroundColor: 'none' }}
                    onClick={this.handleClose}
                    to={'/registration'}
                  >
                    {t('loginForm:registration')}
                  </Link>
                  <Link
                    className="btn-registration"
                    style={{ backgroundColor: 'none' }}
                    onClick={this.handleClose}
                    to={'/restore-password'}
                  >
                    {t('loginForm:restorePassword')}
                  </Link>
                  {this.renderCoopLink()}
                </div>
              </div>
            </fieldset>
          </form>
        </Col>
      </Row>
    );
  }

  renderLoginInput() {
    const { easy, t } = this.props;

    if (easy) {
      return (
        <Input
          label={`E-mail/${t('phone:label')}`}
          type="text"
          name="email-phone"
          id="email-phone-login"
          ref={this.easyLogin}
          onBlur={this.handleBlur.bind(this, 'email-phone')}
        />
      );
    }

    return (
      <Input
        label="E-mail"
        type="email"
        name="email"
        id="email-login"
        ref={this.emailInput}
        onBlur={this.handleBlur.bind(this, 'email')}
      />
    );
  }

  renderCoopLink() {
    if (OptionsStore.isCoopProfile) {
      const { /*toCoop*/ toLegal } = this.props;

      return (
        <Fragment>
          <br />
          {/*
            <span
              className="btn-registration login__too-coop"
              style={{ backgroundColor: 'none' }}
              onClick={toCoop}
            >
          */}
          <span
            className="btn-registration login__too-coop"
            style={{ backgroundColor: 'none' }}
            onClick={toLegal}
          >
            Войти как юридическое лицо
          </span>
        </Fragment>
      );
    }

    return null;
  }

  buttonIcon() {
    return <FontAwesomeIcon icon={signInAlt} />;
  }

  handleClose = (withReset = true) => {
    const { onClose } = this.props;

    LoginStore.reset();
    // if (withReset) {
    //   ProfileParkingStore.setDefaultZoneNumber(null);
    // }
    UIStore.toggleLoginModal();
    onClose();
  };

  handleSubmit = async event => {
    event.preventDefault();

    await this.validateFields();
  };

  validateFields = async () => {
    let globalStatement = true;
    const refs = {
      password: this.passwordInput,
      email: this.emailInput
    };

    const easyRefs = {
      password: this.passwordInput,
      easyLogin: this.easyLogin
    };

    if (this.isEasyLogin) {
      for (const field in easyRefs) {
        const statement = this.validateField(
          easyRefs[field].current.name,
          easyRefs[field].current.value
        );
        globalStatement = globalStatement && statement.success;
        easyRefs[field].current.setErrorState(statement.success, statement.error);
      }
    } else {
      for (const field in refs) {
        const statement = this.validateField(refs[field].current.name, refs[field].current.value);
        globalStatement = globalStatement && statement.success;
        refs[field].current.setErrorState(statement.success, statement.error);
      }
    }

    if (globalStatement) {
      this.submit();
    }
  };

  submit = async () => {
    const { logIn, setTokenToUser, loadProfile, history } = this.props;

    const email = this.isEasyLogin
      ? this.easyLogin.current.value.trim()
      : this.emailInput.current.value.trim();
    const password = this.passwordInput.current.value.trim();

    this._isLoading = true;

    this._formSubmitted = true;
    const token = await logIn(email, password, this.captchaInput);
    this.captchaInput = null;
    this._formSubmitted = false;

    if (token) {
      setTokenToUser(token);
      loadProfile();

      if (ProfileParkingStore.getDefaultZoneNumber()) {
        const path = PathStore.pathWithLang('/profile/parking');
        history.push(path);
      }

      this.handleClose(false);
      return;
    }

    if (this.props.status === LoginStore.errorMessages.emptyPass) {
      const path = `/restore-password?error=${LoginStore.errorMessages.emptyPass}&email=${email}`;

      this.handleClose();
      history.push(PathStore.pathWithLang(path));

      return;
    }

    history.push(PathStore.pathWithLang());
  };

  handleBlur = name => {
    let field = null;
    let value = null;

    switch (name) {
      case 'email-phone':
        field = this.easyLogin.current;
        break;
      case 'email':
        field = this.emailInput.current;
        break;
      case 'pass':
        field = this.passwordInput.current;
        break;
      default:
        return;
    }

    value = field.value !== null ? String(field.value).trim() : null;

    const statement = this.validateField(field, value);

    field.setErrorState(statement.success, statement.error);
  };

  validateField(field, value) {
    const { t } = this.props;
    if (this._valueIsNotEmpty(value)) {
      const emailMatch = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phoneMatch = /^[7|8|\\+7].[0-9]{9,10}$/;
      if (field.name === 'email' && !emailMatch.test(value)) {
        return this._createStatement(false, t('loginForm:errors:email'));
      }

      if (field.name === 'email-phone' && !(emailMatch.test(value) || phoneMatch.test(value))) {
        return this._createStatement(false, t('loginForm:errors:emailPhone'));
      }
    } else {
      return this._createStatement(false, t('loginForm:errors:emptyField'));
    }
    return this._createStatement(true);
  }

  _valueIsNotEmpty = value => {
    return value !== null && typeof value !== 'undefined' && String(value).trim().length > 0;
  };

  _createStatement = (success = true, error) => {
    return {
      success,
      error
    };
  };

  error(error) {
    const { t } = this.props;
    switch (error) {
      case 'Request error':
        return <div className="alert alert-danger">{t('loginForm:errors:error')}</div>;
      case 'UnregistredAccount':
        return (
          <div className="alert alert-danger">{t('loginForm:errors:incorrectLoginOrPassword')}</div>
        );
      case 'LoginTryLimit':
        return <div className="alert alert-danger">{t('loginForm:errors:loginTryLimit')}</div>;
      case 'CaptchaIncorrect':
        return <div className="alert alert-danger">{t('loginForm:errors:CaptchaIncorrect')}</div>;
      case 'IncorrectLogin':
        return (
          <div className="alert alert-danger">{t('loginForm:errors:incorrectLoginOrPassword')}</div>
        );
      case 'error':
        return (
          <div className="alert alert-danger">{t('loginForm:errors:incorrectLoginOrPassword')}</div>
        );
      default:
        return null;
    }
  }

  _bindRefs = ref => {
    this._refs.push(ref);
  };
}

export default StandartLogin;
